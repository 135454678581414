<template>
    <div class="componentWrapper">
        <!-- <div class="homeDarkSection" v-if="$route.name == 'home'">
            <img 
                class="illustration"
                :src="require('@/assets/illustrations/51499810204_887d2cc964_k.jpg')" 
                alt=""
            >
            <img 
                class="whiteLogo"
                :src="require('@/assets/logo/whiteLogo.svg')" 
                alt=""
            >
            <h2>{{ $t("footer.darkSectionText") }}</h2>
            <div class="buttons">
                <RouterLink :to="Tr.i18nRoute({ name: 'products', params: { productUrl: $t(`router.defaultProduct`) } })">{{  $t("footer.darkSectionButtonOne") }}</RouterLink>
                <RouterLink :to="Tr.i18nRoute({ name: 'about' })" style="color: white">{{ $t("footer.darkSectionButtonTwo") }}</RouterLink>
            </div>
        </div> -->
        <div class="footer" :class="{ 'footerSmall': $route.name == 'products' || $route.name == 'about'  || $route.name == 'references' || $route.name == 'privacyPolicy' }">
            <div class="top">
                <div class="data">
                    <!-- <img 
                        class="buLogo" 
                        :src="require('@/assets/images/partners/bu-logo.jpg')"
                    /> -->
                </div>
            </div>
            <div class="bottom">
                <div class="links">
                    <RouterLink :to="Tr.i18nRoute({ name: 'privacyPolicy' })">{{ $t("footer.link2") }}</RouterLink>
                </div>
                <div class="contact">
                    <div class="single">
                        <a href="mailto:info@laukre.ee">info@laukre.ee</a>
                        <img class="inputIcon" :src="require('@/assets/icons/form/email.svg')"/>
                    </div>
                    <div class="single">
                        <span>{{ $t("companyData.mobileNr") }}</span>
                        <img class="inputIcon" :src="require('@/assets/icons/form/phone.svg')"/>
                    </div>
                </div>
                <p id="disclaimer">{{  $t("footer.disclaimer") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
// import languageSwitcher from "@/components/languageSwitcher.vue"
import Tr from "@/i18n/translation"

export default {
    name: 'footerMain',
    setup() {
        return { Tr } 
    },
    components: {
        // languageSwitcher
    },
    methods: {
        
    }
}
</script>

<style scoped>
.componentWrapper {position: relative;}
.homeDarkSection {
    position: relative;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 30px;
    background: rgb(66, 74, 95);
    -webkit-clip-path: ellipse(65% 100% at top center);
            clip-path: ellipse(65% 100% at top center);
    z-index: 0;
}
.illustration {
    position: absolute;
    left: -0px;
    top: -0px;
    width: 100%;
    opacity: .25;
}
.homeDarkSection h2 {
    position: relative;
    z-index: 10;
    color: rgb(49, 49, 49);
    font-size: 45px;
}
.buttons {
    position: relative;
    z-index: 10;
    margin-top: 30px;
}
.buttons a {
    text-decoration: none;
    color: rgb(49, 49, 49);
}
.buttons a:first-child {
    text-decoration: none;
    color: rgb(49, 49, 49);
}
.buttons a {
    padding: 9px 75px 9px 75px;
    border: 0px solid transparent;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 22px;
    color: rgb(255, 255, 255);
    background: white;
}
.buttons a:last-child {
    background: transparent;
    border: 2px solid white;
    color: white;
}
.whiteLogo {
    position: relative;
    z-index: 10;
    width: 240px;
}
.footer {
    position: relative;
    width: 1300px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.footerSmall {
    width: 1200px;
}
.top {
    width: 100%;
    height: 120px;
    position: relative;
    text-align: start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.buLogo {
    width: 300px;
    margin-top: 0px;
}
.contact {
    position: absolute;
    right: 0;
    margin-top: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.contact span {
    font-size: 18px;
    color: rgb(49, 49, 49);;
}
.contact img {
    position: absolute;
    right: 0;
    margin-top: -4px;
    width: 30px;
}
.single {
    margin-bottom: 15px;
    text-align: end;
    padding-right: 40px;
}
.data {
    width: 300px;
    margin-top: 30px;
}
.data span {
    font-size: 18px;
    color: rgb(49, 49, 49);;
}
.singleData {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
}
.singleData p {
    font-size: 22px;
    color: rgb(49, 49, 49);;
}
.singleData img {
    width: 30px;
    margin-right: 10px;
}
.bottom {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-top: 2px solid rgb(235,235,235);
    padding-top: 15px;
    padding-bottom: 25px;
}
.bottom a {
    margin-right: 20px;
    font-weight: bold;
    color: rgb(49, 49, 49);;
    cursor: pointer;
    text-decoration: none;
}
.bottom p {
    text-align: start;
    margin-top: 15px;
    font-size: 14px;
    width: 100%;
    color: rgb(49, 49, 49);;
}
.single a {
    font-size: 18px;
    color: rgb(49, 49, 49);;
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal;
    margin-right: 0;
}
.langSwitcher {
    position: absolute;
    bottom: 15px;
    right: 0;
}

@media (max-width: 1350px) {
    .footer {
        width: 100%;
    }
    .top {
        width: 95%;
        margin: 0 auto;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .bottom {
        width: 95%;
        margin: 0 auto;
    }
}
@media (max-width: 1050px) {
    .homeDarkSection {
        -webkit-clip-path: ellipse(90% 100% at top center);
                clip-path: ellipse(90% 100% at top center);
    }
}
@media (max-width: 750px) {
    .homeDarkSection {
        padding-top: 20px;
        padding-bottom: 60px;
        -webkit-clip-path: ellipse(120% 100% at top center);
                clip-path: ellipse(120% 100% at top center);
    }
    .illustration {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
    }
    .homeDarkSection h2 {
        font-size: 36px;
    }
    .buttons {
        margin-top: 25px;
    }
    .buttons a {
        padding: 7px 45px 7px 45px;
    }
    .whiteLogo {
        width: 200px;
    }
}
@media (max-width: 650px) {
    .contact {
        width: 100%;
    }
    .footer {
        margin: -100px auto;
        margin-bottom: 0;
    }
}
@media (max-width: 520px) {
    #disclaimer {
        display: none;
    }
    .footer {
        padding-bottom: 20px;
    }
}
@media (max-width: 500px) {
    .homeDarkSection h2 {
        font-size: 32px;
    }
    .whiteLogo {
        width: 180px;
    }
    .langSwitcher {
        bottom: -40px;
    }
    .links {
        margin-top: -45px;
    }
    .footer {
        padding-bottom: 50px;
    }
}
@media (max-width: 450px) {
    .top {
        width: 92%;
    }
    .bottom {
        width: 92%;
    }
    .data  {
        width: 100%;
        text-align: start;
    }
    .data span {
        font-size: 16px;
    }
}
@media (max-width: 390px) {
    .homeDarkSection h2 {
        font-size: 30px;
        margin-top: 5px;
    }
    .buttons a {
        padding: 7px 25px 7px 25px;
    }
    .whiteLogo {
        width: 180px;
    }
}
</style>
