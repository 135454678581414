<template>
    <div class="bannerWrapper">
        <!-- <img 
            class="illustration"
            :src="require('@/assets/illustrations/drawings/skyscape.svg')" 
            alt=""
        > -->
        <!-- <img 
            class="lines"
            :src="require('@/assets/illustrations/drawings/lines.svg')" 
            alt=""
        > -->
        <img src="@/assets/illustrations/drawings/Maskgroup(2).png" class="shape-image" alt="shape image">
        <div class="banner">
            <img 
                class="logoImg"
                :src="require('@/assets/logo/laukreWhite2.svg')" 
                alt=""
            >
            <!-- <div class="text">
                <h2 class="title" :class="{ 'russian-font-size': $i18n.locale === 'ru' }" v-html="$t(`home.bannerText`)"></h2>
                <button @click="$emit('openContactPopup')" :class="{ 'russian-font-size-button': $i18n.locale === 'ru' }">{{ $t(`buttons.viewProducts`) }}</button>
                <button @click="$emit('scrollToProducts')" :class="{ 'russian-font-size-button': $i18n.locale === 'ru' }">{{ $t(`buttons.contact`) }}</button>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'homeBanner',
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>
.shape-image {
    position: absolute;
    width: 60%;
    height: auto;
    top: 0px;
    bottom: 55px;
    left: auto;
    overflow: hidden;
    background-size: cover;
    right: 0;
}
.bannerWrapper {
    position: relative;
    margin-bottom: 50px;
    height: 460px;
    background: #26282c;
    margin-top: -80px;
}
.banner {
    position: relative;
    width: 1300px;
    height: 400px;
    margin: 0 auto;
    text-align: start;
}
.illustration {
    position: absolute;
    width: 450px;
    right: 50px;
    bottom: 0px;
    opacity: 1;
}
.lines {
    position: absolute;
    width: 200px;
    left: 150px;
    bottom: 0px;
    opacity: 1;
    -webkit-transform: rotate(280deg);
        -ms-transform: rotate(280deg);
            transform: rotate(280deg);
}
.logoImg {
    position: absolute;
    top: 120px;
    left: 0px;
    z-index: 10;
    width: 400px;
    height: auto;
}
.text {
    position: absolute;
    right: 50px;
    bottom: 0;
    top: 0;
    margin: auto;
    font-size: 28px;
    width: 550px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.text h2 {
    color: #3498DB;
}
.title >>> span {
    color: #FFBD59;
}
.russian-font-size {
    font-size: 32px;
}
button {
    width: 240px;
    height: 55px;
    border: 2px solid transparent;
    border-radius: 15px;
    margin-right: 25px;
    margin-left: 0px;
    margin-top: 30px;
    font-weight: bold;
    font-size: 20px;
    background: #3498DB;
    color: white;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
button:last-child {
    background: rgb(255, 255, 255);
    border: 2px solid #394A68;
    color: #394A68;
    margin-right: 0;
}
button:hover {
    background: rgb(226, 228, 233);
    border: 2px solid #394A68;
    color: #394A68;
    cursor: pointer;
}
button:last-child:hover {
    color: white;
    background: rgb(66, 74, 95);
}
.russian-font-size-button {
    font-size: 18px;
}
@media (max-width: 1350px) {
    .banner {
        width: 100%;
    }
    .logoImg {
        left: 25px;
    }
    .text {
        right: 25px;
        bottom: 0;
        top: 0;
        margin: auto;
        font-size: 28px;
        width: 550px;
    }
    .illustration {
        position: absolute;
        bottom: -2px;
        opacity: 1;
    }
}
@media (max-width: 1150px) {
    .text {
        right: -10px;
        top: 17px;
        font-size: 24px;
    }
    button {
        width: 240px;
    }
}
@media (max-width: 1125px) {
    .bannerWrapper {
        height: 450px;
        margin-bottom: 50px;
    }
    .logoImg {
        left: 0;
        right: 0;
        top: 150px;
        margin: auto;
    }
    .text {
        left: 0;
        right: 0;
        bottom: 0;
        top: 300px;
        margin: auto;
        font-size: 20px;
        text-align: center;
    }
    .illustration {
        display: none;
    }
    .lines {
        width: 150px;
        left: 120px;
        top: 300px;
        opacity: .8;
    }
}
@media (max-width: 720px) {
    .logoImg {
        top: 100px;
    }
    .text {
        top: 280px;
    }
    .lines {
        top: 200px;
    }
}
@media (max-width: 550px) {
    .bannerWrapper {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 20px;
    }
    .banner {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-bottom: 100px;
        text-align: center;
    }
    .logoImg {
        position: relative;
        width: 85%;
        margin-bottom: 10px;
    }
    .text {
        position: relative;
        top: 25px;
        width: 90%;
        font-size: 18px;
    }
    button {
        width: 45%;
    }
    .illustration {
        width: 121%;
    }
}
@media (max-width: 430px) {
    button {
        width: 47%;
        margin-right: 10px;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .lines {
        top: -0px;
        left: 100px;
        -webkit-transform: rotate(260deg);
            -ms-transform: rotate(260deg);
                transform: rotate(260deg)
    }
}
</style>